import React from 'react';
import styled from 'styled-components';

function Button(props) {
  const {
    size = 'defualt', // default | small
    onClick = () => 0,
    disabled = false,
    loading = false,
    type = 'primary', // primary | secondary
    ...otherProps
  } = props;
  return (
    <Wrapper
      className={size !== 'default' ? size : ''}
      disabled={disabled}
      type={type}
      onClick={onClick}
      {...otherProps}>
      {loading && <Loader />}
      {typeof props.children === 'string' ? (
        <span>{props.children}</span>
      ) : (
        props.children
      )}
    </Wrapper>
  );
}

const Loader = styled.div`
  border: 3px solid rgba(243, 243, 243, 63%);
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  animation: spin 0.8s linear infinite;
  display: inline-block;
  margin-right: 10px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Wrapper = styled.button`
  /* overwrite defualt button style */
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;

  --background-color: #846e4f;
  --hover-background-color: #61513a;
  --text-color: #fff;
  --disabled-backgtound-color: #ccbfb0;

  ${(props) =>
    props.type === 'secondary'
      ? `
  --background-color: #1E2F29;
  --hover-background-color: #41504b;
  --text-color: #fff;
  --disabled-backgtound-color: #688279;
  `
      : ''}

  &:focus {
    outline: none;
  }

  &:hover {
    background: var(--hover-background-color);
  }

  width: 200px;
  height: 40px;
  color: var(--text-color);
  background-color: var(--background-color);
  transition: background-color 0.1s ease-in;
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
    background-color: var(--disabled-backgtound-color);
  }
  text-align: center;
  font-family: 'Noto Sans TC';

  font-size: 14px;
  line-height: 25px;

  & > span {
    vertical-align: middle;
  }

  &.small {
    font-size: 15px;
    line-height: 22px;
    height: 36px;
  }
`;

export default Button;
