import {Warning} from '@styled-icons/fluentui-system-regular/Warning';
import {message} from 'antd';
import {navigate} from 'gatsby';
import qs from 'query-string';
import React from 'react';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import styled from 'styled-components';

import * as CartActions from '../../Actions/Cart';
import * as AppActions from '../../AppActions';
import Button from '../../Components/Button';
import ProductVariants from '../../Components/ProductVariants';
import RichTextPreview from '../../Components/RichTextPreview';
import {
  CART_ITEMS_LIMIT,
  LOGISTIC_TYPE,
  PRODUCT_TYPE,
} from '../../Domain/Constants';
import * as ProductUtils from '../../Domain/Product';
import FixedRatioImage from '../../Components/FixedRatioImage';

function ProductDetail(props) {
  const prefixPath = '/product';
  const [product, setProduct] = React.useState(null);
  const [quantity, setQuantity] = React.useState(1);
  const [currItemConfig, setCurrItemConfig] = React.useState(null);
  const [currPrice, setCurrPrice] = React.useState(null);
  const [article, setArticle] = React.useState(null);
  const [imgIdx, setImgIdx] = React.useState(0);
  const [dimension] = useOutlet('dimension');
  const params = qs.parse(props.location.search);
  const [cart] = useOutlet('cart');
  const [user] = useOutlet('user');
  const showLoginModal = useOutletSetter('login-modal');
  const {id} = params;
  const [actions] = useOutlet('actions');
  const [specs, setSpecs] = React.useState(null);

  React.useEffect(() => {
    const _fetchSpecs = async () => {
      try {
        let specResp = await actions.fetchDocuments(
          'spec',
          {
            $or: product.specs.map((sid) => ({id: sid})),
          },
          ['created'],
          {},
          null,
          {anonymous: true},
        );

        let _validSpecs = specResp.results.filter(ProductUtils.isAllowSpec);
        setSpecs(_validSpecs);
        let _spec = _validSpecs[0]?.id || null;

        setCurrItemConfig((_curConfig) => ({
          ..._curConfig,
          spec: _spec,
        }));
      } catch (err) {
        message.error('無法取得規格資訊');
      }
    };

    if (product?.specs && specs === null) {
      _fetchSpecs();
    }
  }, [product, actions, specs]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        actions.setLoading(true);
        const resp = await actions.clientJStorageFetchById('product', id);
        resp.images = (resp.images || []).map((i) => ({url: i.expected_url}));
        setProduct(resp);
        setQuantity(1);
        setImgIdx(0);
        actions.setLoading(false);

        // don't show global spinner for article fetching
        if (resp.article) {
          setArticle(await actions.clientFetchArticleById(resp.article));
        }
      } catch (ex) {
        console.warn(ex);
      } finally {
        actions.setLoading(false);
      }
    }

    fetchData();
  }, [id, actions]);

  const onNextConfig = React.useCallback((nextItemConfig, calcResp) => {
    setCurrPrice(calcResp.amount);
    setCurrItemConfig(nextItemConfig);
  }, []);

  function renderCustomSection(sectionId, data) {
    return AppActions.renderCustomSection({
      route: prefixPath,
      sectionId,
      params,
      data,
    });
  }

  async function addToCart() {
    if (!user) {
      showLoginModal(true);
      return;
    }
    let result = false;
    try {
      AppActions.setLoading(true);

      if (!currItemConfig.logistic_type) {
        message.error('請選擇物流方式');
        return;
      }

      let inCartIndex = cart.items.findIndex(
        (item) =>
          item.product.id === product.id &&
          item.config.spec === currItemConfig.spec,
      );

      console.log('inCartIndex', inCartIndex);
      if (inCartIndex !== -1) {
        // item already in cart
        console.log(cart.items[inCartIndex]);
        let _itemInCart = cart.items[inCartIndex];
        let _nextQty = _itemInCart.config.qty + currItemConfig.qty;
        if (_nextQty > CART_ITEMS_LIMIT) {
          const error = new Error('over_cart_items_limits');
          error.body = {
            error: 'over_cart_items_limits',
          };
          throw error;
        }

        // check logistic type first before merge, mimic BE's error
        if (currItemConfig.logistic_type !== cart.logistic_type) {
          throw {
            body: {
              error: 'invalid_logistic_type',
            },
          };
        }

        await CartActions.editCart(inCartIndex, {
          qty: _nextQty,
          variants: [],
          spec: _itemInCart.config.spec,
          logisticType: currItemConfig.logistic_type,
        });
      } else {
        await CartActions.addToCart(product.id, {
          ...currItemConfig,
          logisticType: currItemConfig.logistic_type,
        });
      }
      message.success('已加入購物車');
      result = true;
    } catch (ex) {
      if (ex && ex.body) {
        if (ex.body.error === 'invalid_logistic_type') {
          message.warn('由於運送限制，請確認與購物車內商品運送方式相同');
        } else if (ex.body.error === 'over_cart_items_limits') {
          message.warn('已超過單次購買數量限制');
        } else if (ex.body.error === 'invalid_delivery_date') {
          message.warn(
            '請確認購物車商品到貨日相同，若需購買不同到貨日商品，請分開下訂',
            3,
          );
        } else if (ex.body.error === 'out_of_stock') {
          message.warn(
            '您選擇的出貨日期庫存不足，請嘗試其他數量或是另選出貨日期',
          );
        } else if (ex.body.error === 'qty_out_of_limit') {
          message.warn('超過購物車購買數量上限');
        } else if (ex.body.error === 'qty_exceed_limit') {
          message.warn('超過此商品購買數量上限');
        } else {
          message.error('發生錯誤, 請稍後再重新嘗試');
        }
      } else {
        message.error('發生錯誤, 請稍後再重新嘗試');
      }
      result = false;
    } finally {
      AppActions.setLoading(false);
    }
    return result;
  }

  if (!product) {
    return null;
  }

  return (
    <Wrapper>
      {renderCustomSection('A', {product})}

      <div className="content">
        {renderCustomSection('B', {product})}

        <TopSection>
          <Gallery dimension={dimension} style={{marginTop: 20}}>
            {product.images && product.images[imgIdx] && (
              <FixedRatioImage
                ratio={545 / 668}
                image={product.images[imgIdx].url}
                mode="cover"
              />
            )}

            {product.images && (
              <MiniImageList>
                {product.images.map((image, idx) => (
                  <MiniImageItem
                    src={image.url}
                    alt="mini"
                    key={idx}
                    selected={idx === imgIdx}
                    onClick={() => setImgIdx(idx)}
                  />
                ))}
              </MiniImageList>
            )}
          </Gallery>

          <Summary>
            {renderCustomSection('C', {product})}
            <h2 className="product-name">{product.name}</h2>
            <p className="product-description">
              {product.description || '---'}
            </p>
            {renderCustomSection('D', {product})}

            <ProductVariants
              product={product}
              config={currItemConfig}
              onNextConfig={onNextConfig}
              rowStyle={{justifyContent: 'center'}}
              specs={specs || []}
            />

            <InputField style={{justifyContent: 'center', marginTop: 40}}>
              {product.is_sold_out ? (
                <h2 style={{color: '#846e4f'}}>已售完</h2>
              ) : (
                <div className="sum">
                  <h2 className="currency">TWD</h2>
                  <h2 className="price">$ {currPrice || product.price}</h2>
                </div>
              )}
            </InputField>

            <InputField
              style={{
                justifyContent: 'center',
                flexDirection: dimension.rwd === 'mobile' ? 'column' : 'row',
                marginTop: 30,
                marginBottom: 40,
              }}>
              <Button
                type="primary"
                disabled={!currItemConfig?.spec}
                onClick={addToCart}
                style={{
                  marginRight: dimension.rwd === 'mobile' ? 0 : 10,
                  marginBottom: dimension.rwd === 'mobile' ? 10 : 0,
                }}>
                加入購物車
              </Button>
              <Button
                type="secondary"
                disabled={!currItemConfig?.spec}
                onClick={async () => {
                  const result = await addToCart();
                  if (result) {
                    navigate('/checkout');
                  }
                }}>
                立即購買
              </Button>
            </InputField>
            <div className="more">
              <details>
                <summary>MORE DETAILS</summary>
                <p>{product?.more_details}</p>
              </details>
              <details>
                <summary>SHIPPING DETAILS</summary>
                <p>{product?.shipping_details}</p>
              </details>
              <span className="hint">
                <span>
                  <Warning size={16} color="#fff" />
                </span>{' '}
                <span>出貨日並非配達日</span>
              </span>
              <span className="faq-hint">
                <span>若尚有其他問題，請詳閱我們的</span>
                <span
                  className="faq-link"
                  onClick={async () => {
                    await navigate('/faq');
                  }}>
                  購物FAQ
                </span>
              </span>
            </div>
          </Summary>
        </TopSection>

        {renderCustomSection('E', {product})}

        {article && (
          <ProductArticle>
            <RichTextPreview content={article.content} />
          </ProductArticle>
        )}

        {renderCustomSection('F', {product})}
      </div>

      {renderCustomSection('G', {product})}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  & > .content {
    max-width: var(--contentMaxWith);
    margin: 0 auto;
    padding: var(--basePadding);
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 0;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Gallery = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Summary = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 700px;
  & > .product-name {
    font-size: 20px;
    margin: 20px 0;
    text-align: center;
  }
  & > .product-description {
    margin: 20px auto;
    text-align: center;
    width: 100%;
    max-width: 300px;
    font-size: 14px;
    white-space: pre-line;
  }

  & > .more {
    font-size: 14px;
    width: 100%;
    margin: 0 auto;
    max-width: 410px;
    & details {
      color: #846e4f;
      margin-bottom: 10px;
      & > p {
        white-space: pre-line;
      }
    }
    & details summary {
      cursor: pointer;
    }

    & details[open] {
      color: #000;
    }
    & details[open] summary {
      color: #000;
    }
    & > .hint {
      align-self: flex-start;
      background-color: #846e4f;
      color: #fff;
      text-align: center;
      padding: 0 10px;
      margin-bottom: 15px;
    }
    & > .faq-hint {
      color: #846e4f;
      display: flex;
      justify-content: flex-end;
      padding: 5px;
      border-bottom: 1px solid #846e4f;
      & > .faq-link {
        color: blue;
        text-decoration: underline;
        padding-left: 5px;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const InputField = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  & > label {
    margin-right: 10px;
  }
  & > .sum {
    display: flex;
    align-items: baseline;
    & > .currency {
      font-size: 20px;
      margin-right: 20px;
    }
    & > .price {
      font-size: 30px;
      font-weight: bold;
    }
  }
`;

const ProductArticle = styled.div`
  min-height: 700px;
  & img {
    max-width: 800px;
    margin: 0 auto;
  }
`;

const MiniImageList = styled.div`
  display: flex;
  overflow: auto;
`;

const MiniImageItem = styled.img`
  width: 100px;
  height: 100px;
  margin: 8px;
  border: 2px solid ${(props) => (props.selected ? '#ccc' : 'transparent')};
  border-radius: 0px;
  object-fit: contain;
  cursor: pointer;

  &:first-child {
    margin-left: 0px;
  }
`;

export default ProductDetail;
